import LandingPageComponent from '../component/landing';
import React from 'react';

const IndexPage = () => {
  return (
    <LandingPageComponent isFinal={true} isTerm={true} isUniversal={true} />
  );
};

export default IndexPage;
